export default {
  OW: 'Một chiều',
  RT: 'Khứ hồi',
  MC: 'Nhiều chặng',

  Infant: 'Em bé',
  Child: 'Trẻ em',
  Adult: 'Người lớn',
  numPassenger: 'Số lượng hành khách',
  adultRange: 'Từ 12 tuổi',
  childRange: 'Từ 2 - dưới 12 tuổi',
  infantRange: 'Dưới 2 tuổi',

  airlineSearchPlaceholder: 'Chọn hệ thống đặt vé',
  carriersSearchPlaceholder: 'Chọn hãng vận chuyến (1G)',
  classFilterSearchPlaceholder: 'Chọn hạng ghế',
  searchFlight: 'Tìm chuyến bay',
  search: 'Tìm kiếm',

  loadingAirport: 'Lấy danh sách sân bay...',
  loading: 'Đang tải...',
  noResultFound: 'Không tìm thấy kết quả cho ',
  noOptions: 'Xin lỗi, không có tùy chọn phù hợp.',
  startDate: 'Ngày đi',
  returnDate: 'Ngày về (khứ hồi)',

  sortBy: 'Sắp xếp theo',
  filters: 'Bộ lọc',
  maxPrice: 'Giá tối đa',
  minPrice: 'Giá tối thiểu',
  clearAllFilters: 'Xoá lọc',
  earliestFlight: 'Sớm nhất',
  cheapestFlight: 'Rẻ nhất',
  fastestFlight: 'Nhanh nhất',
  rangePrice: 'Khoảng giá',
  airSource: 'Hệ thống đặt vé',
  airline: 'Hãng hàng không',
  airlineShort: 'Hãng',
  airCraft: 'Loại máy bay',
  numberOfStop: 'Số điểm dừng',
  departureTime: 'Giờ khởi hành',
  Early: 'Sớm',
  Morning: 'Buổi sáng',
  Afternoon: 'Buổi chiều',
  Evening: 'Buổi tối',
  arrivalTime: 'Thời gian đến',
  transitPoints: 'Điểm quá cảnh',
  flightDuration: 'Thời gian bay',
  totalDuration: 'Tổng thời gian bay',
  Facilities: 'Tiện ích',
  'Price/passenger': 'Giá/Hành khách',
  flightPreference: 'Chuyến bay ưu tiên',
  'Refund/Reschedule': 'Hoàn lại tiền',
  showPrice: 'Hiển thị giá',
  viewConfig: {
    CHEAPEST_FARE: 'Giá rẻ nhất',
    EXPENSIVEST_FARE: 'Giá đắt nhất',
    AIRLINE: 'Hãng hàng không',
    EARLIEST_DEPARTURE: 'Khởi hành sớm nhất',
    LATEST_DEPARTURE: 'Khởi hành muộn nhất',
    EARLIEST_ARRIVAL: 'Hạ cánh sốm nhất',
    LATEST_ARRIVAL: 'Hạ cánh muộn nhất',
    SHORTEST_DURATION: 'Bay nhanh nhất',
    LONGEST_DURATION: 'Bay chậm nhất',
    TOTAL_FARE: 'Giá tổng',
    NET_FARE: 'Giá NET',
    TICKET_FARE: 'Giá vé',
    HIDE_FARE: 'Ẩn giá',
    discountedPrice: 'Giá đã chiết khấu',
    showFeeService: 'Hiện phí dịch vụ',
    showAgService: 'Hiện phí xuất vé',
    showBookingClass: 'Hiện hạng vé',
    showAircraft: 'Hiện loại máy bay',
  },
  flightDetails: 'Chi tiết hành trình',
  startFrom: 'Khởi hành',
  endAt: 'Điểm đến',
  midAt: 'Giữa chặng',
  changePlane: 'Đổi máy bay',
  TakeOffFrom: 'Từ: ',
  landingAt: 'Đến: ',
  flightNumber: 'Số hiệu',
  fareType: 'Loại vé',
  Baggage: 'Hành lý',
  cabinBaggage: 'Xách tay',
  Conditions: 'Điều kiện',
  'fare&benefit': 'Giá vé & Quyền lợi',
  noRefund: 'Không áp dụng hoàn vé',
  Refund: 'Được hoàn vé',
  refundTitle: 'Hoàn vé',
  refundTitleVJ: 'Hoàn bảo lưu định danh',
  'Refund&Fee': 'Áp dụng đổi vé mất phí',
  noReschedule: 'Không áp dụng đổi lịch',
  Reschedule: 'Đổi lịch',

  priceDetails: 'Chi tiết giá',
  Tax: 'Thuế và phí',
  Save: 'Giảm giá',
  Total: 'Tổng tiền',
  tempTotal: 'Tổng tiền (tạm tính)',

  benefit: 'Quyền lợi',
  refundPolicy: 'Chính sách hoàn tiền',
  refundProcess: 'Quy trình hoàn tiền',
  refundInfo: 'Thông tin hoàn tiền',
  Cancel: 'Hủy bỏ',
  Next: 'Tiếp tục',
  Back: 'Quay lại',
  Reservation: 'Đặt chỗ',
  Review: 'Xác nhận',

  contactInfo: 'Thông tin liên hệ',
  reserCodeSend: 'Mã đặt chỗ sẽ được gửi theo thông tin liên hệ dưới đây.',
  agencyInfo: 'Thông tin đại lý',
  customerInfo: 'Thông tin khách hàng',
  reserCodeSendAG: 'Mã đặt chỗ sẽ được gửi theo thông tin đại lý dưới đây.',
  lastName: 'Họ',
  firstName: 'Tên đệm & Tên',
  email: 'Email',
  phone: 'Số điện thoại',
  nationality: 'Quốc tịch',
  placeholderInput: 'Vui lòng nhập',
  placeholderSelect: 'Vui lòng chọn',
  Honorific: 'Danh xưng',
  phoneNumber: 'Số điện thoại',

  passengerInfo: 'Thông tin hành khách',
  passengerInfoNote:
    'Nhập tiếng Việt không dấu, tên hành khách phải chính xác như trong CCCD/Hộ chiếu/Giấy phép lái xe.',
  sameInfoContact: 'Thông tin hành khách là thông tin liên hệ',
  labelAdult2: 'Họ và tên - Người lớn 2',

  quickInputPaxInfo: 'Nhập nhanh thông tin nhiều hành khách',
  quickInputPaxInfoNoteQuantity: 'Bạn đang thực hiện nhập nhanh thông tin cho:',
  adultPlural: 'người lớn | người lớn',
  childPlural: 'trẻ em | trẻ em',
  infantdPlural: 'em bé | em bé',
  quickInputPaxInfoNote: `- Mỗi dòng là thông tin của một hành khách, không phân biệt chữ hoa, chữ thường:<br>
- Danh xưng người lớn: <span class="text-danger font-medium-1">Mr | Mrs | Ms</span>. Trẻ em, em bé: <span class="text-danger font-medium-1">Mstr | Miss</span>
<br>
<div class="text-dark font-small-4 font-weight-bolder mb-50">- Ví dụ: </div>
<span class="text-warning font-medium-1 font-weight-bolder ml-md-1 mb-50"> TRAN VAN NAM MR</span><br>
<span class="text-warning font-medium-1 font-weight-bolder ml-md-1"> LE THI THU MRS</span><br>
<em class="mt-50">Nhập nhanh ngày sinh theo định dạng: 15/05/1996</em>`,

  extraBaggage: 'Thêm hành lý ký gửi',
  extraBaggageText: 'Thêm hành lý cho chuyến đi. Rẻ hơn mua tại quầy.',

  DiscountCode: 'Mã giảm giá',
  selectDiscount: 'Chọn mã giảm giá',
  selectDiscountPlaceholder: 'Nhập mã giảm giá',
  useCode: 'Sử dụng',
  selectCode_1: 'Hoặc chọn một mã giảm giá dưới đây',
  selectCode_2: 'Mã',
  selectCode_3: 'Không tìm thấy mã giảm giá nào.',

  exportReceipt: 'Xuất hoá đơn',
  exportReceiptText:
    'Khi cần xuất hoá đơn GTGT, Quý khách vui lòng gửi yêu cầu trong 48h (và không trễ hơn 22h tối ngày cuối cùng) trên ứng dụng.',
  requestInvoice: 'Yêu cầu xuất hoá đơn',
  termsInvoice: 'Điều khoản xuất hoá đơn',

  buyIns: 'Mua bảo hiểm',
  buyInsText: 'Người',

  paymentMethod: 'Phương thức thanh toán',
  paymentMethodText:
    'Sau khi hoàn tất thanh toán, vé điện tử sẽ được gửi ngay qua SMS và Email của bạn.',
  issueTicket: 'Xuất vé',
  Reservations: 'Giữ chỗ',
  reservationsIn: 'Giữ chỗ trong',

  Buy: 'Thanh toán',
  buyText_1:
    'Bằng cách nhấn vào nút này, bạn công nhận mình đã đọc và đồng ý với ',
  buyText_2: 'Điều kiệThuến và Điều khoản',
  buyText_3: ' của chúng tôi.',

  trip: 'Chuyến',
  totalFare: 'Tổng chi phí',
  total: 'Tổng',
  discount: 'Chiết khấu',
  Promotion: 'Hoa hồng',
  fareText: 'Đã bao gồm thuế, phí',

  sourceBy: 'Vận hành bởi',
  direct: 'Bay thẳng',
  '1_transits': '1 điểm dừng',
  '2_transits': '2 điểm dừng',

  seatAvailable: 'Số chỗ',
  createBooking: 'Giữ chỗ',
  Creating: 'Đang tạo',
  addonAncillary: 'Dịch vụ bổ sung',
  detailAddonAncillary: 'Thêm dịch vụ cho chuyến đi. Rẻ hơn khi mua tại quầy.',
  noAncillary: 'Không có dịch vụ bổ sung khả dụng cho chuyến bay này!',
  flightSegment: 'Chặng bay',
  addons: 'Dịch vụ thêm',
  addonsType: 'Loại dịch vụ',
  bag: 'Hành lý',
  addBag: 'Hành lý mua thêm',
  extra: 'Dịch vụ khác',
  passenger: 'Hành khách',
  servicePlaceholder: 'Chọn gói',
  Amount: 'Số lượng',
  package: 'gói',
  clear: 'Xoá',
  addService: 'Thêm dịch vụ',
  addAdult: 'Thêm người lớn',
  addChild: 'Thêm trẻ em',
  addInfant: 'Thêm em bé',
  dob: 'Ngày sinh (DD-MM-YYYY)',
  Details: 'Chi tiết',
  memberFly: 'Mã thẻ thành viên',
  selectAdult: 'Chọn người lớn',
  requiredAdult: 'Em bé phải đi cùng với một người lớn',
  maxLengthInfant:
    'Số em bé không được nhiều hơn số lượng hành khách người lớn.',
  expirationDate: 'Ngày hết hạn',
  issueDate: 'Ngày cấp',
  gender: 'Giới tính',
  stopChangePlane: 'Dừng lại để thay đổi máy bay',
  selectSeats: 'Chọn chỗ ngồi',
  selectSeatsDetail: 'Chọn chỗ ngồi trên chuyến bay',
  noSeatService: 'Không hỗ trợ chọn chỗ trên chặng này!',
  seatNumber: 'Số ghế',
  Row: 'Hàng',
  seatDescription: 'Mô tả',
  Price: 'Giá',
  priceDetail: 'Thông tin giá vé, phí và các dịch vụ kèm theo',
  serviceCharge: 'Phí dịch vụ',
  Discount: 'Chiết khấu',
  noteDiscount: 'Tổng tiền chưa bao gồm hoa hồng',
  Service: 'Dịch vụ',
  Action: 'Hành động',
  exportIMG: 'Xuất Ảnh',
  exportPDF: 'Xuất PDF',
  renameExport: 'Thay đổi tên tệp khi xuất',
  filename: 'Tên tệp',
  filenamePlaceholder: 'Vui lòng nhập tên tệp',
  Nationality: 'Quốc tịch',
  Passport: 'Số hộ chiếu',
  placeIssue: 'Nơi cấp',
  totalPriceItineraries: 'Tổng giá hành trình',

  cheapestFareNotFound: 'Không có dữ liệu',

  noAddonsResult: 'Không có dịch vụ bổ sung được lựa chọn.',
  registerMembershipCard: 'Đăng kí thẻ thành viên',
  nationalityPlaceholder: 'Lựa chọn quốc tịch',
  paynow: 'Thanh toán ngay',
  confirm: 'Xác nhận',
  confirmPrice: 'Xác nhận thanh toán',
  close: 'Đóng',
  seatDetails: 'Chi tiết chỗ ngồi',
  Seat: 'Ghế',
  passengerMore9:
    'Số người lớn và trẻ em không vượt quá chín (9) hành khách trong một lần đặt chỗ.',
  passengerMore:
    'Số người lớn và trẻ em không vượt quá {limitPax} hành khách trong một lần đặt chỗ.',
  directFlightSelect: 'Chuyến bay thẳng',
  Available: 'Ghế trống',
  Unavailable: 'Đã có người',
  atStopPoint: 'Tại điểm dừng này, bạn cần',
  atStopPoint_1: 'Thu thập và kiểm tra lại hành lý của bạn',
  modalMemberShipCard: 'Đăng ký thẻ thành viên',
  labelSelectAirlines: 'Chọn hãng bay muốn tạo thẻ thành viên',
  copyPrice: 'Báo giá',
  nextFlight: 'Chuyến tiếp theo',
  submit: 'Xác nhận',
  promoCode: 'Mã giảm giá',
  searching: 'Đang tìm kiếm',
  notBookingFound: 'Hành trình được chọn đã hết vé',

  Window: 'Ghế ngồi cạnh cửa sổ',
  AisleSeat: 'Ghế ngồi cạnh lối đi',
  ExitRowSeat: 'Ghế ngồi cạnh lối thoát hiểm',
  RightSideOfAircraft: 'Ghế ngồi ở phía bên phải của máy bay',
  LeftSideOfAircraft: 'Ghế ngồi ở phía bên trái của máy bay',
  OverWingSeat: 'Ghế ngồi gần cánh máy bay',
  CenterSectionSeat: 'Ghế ngồi ở trung tâm của máy bay',
  LimitedRecline: 'Ghế ngồi ngả lưng giới hạn',
  BulkheadFront: 'Ghế ngồi chỗ để chân rộng',

  FrontOfCabinClassCompartment: 'Mặt trước hạng/khoang cabin',
  SeatSuitableForAdultWithAnInfant: 'Ghế phù hợp cho người lớn có trẻ sơ sinh',
  BufferZoneSeat: 'Ghế vùng đệm (dành cho người dị ứng)',
  ChargeableSeats: 'Ghế ngồi tính phí',
  RestrictedSeatGeneral: 'Chỗ ngồi hạn chế',
  SeatWithBassinetFacility: 'Ghế ngồi có nôi em bé',
  NoFacilitySeatIndifferentSeat: 'Không có ghế ngồi tiện nghi',

  seatType: 'Loại ghế',
  addAnotherFlight: 'Thêm chuyến bay',
  notSupportSeat: 'Không hỗ trợ chọn chỗ trên chặng này!',
  deletePassenger: 'Xoá hành khách',
  confirmDeletePassenger: 'Bạn có chắc chắn muốn xoá hành khách?',
  cannotDeletePassenger: 'Không thể xoá hành khách!',
  leastOneAdult: 'Đặt vé phải có ít nhất một hành khách NGƯỜI LỚN!',
  toAddNewInf: 'Vui lòng tìm kiếm lại chuyến bay để thêm mới EM BÉ.',
  toAddNewChd: 'Vui lòng tìm kiếm lại chuyến bay để thêm mới TRẺ EM.',
  transitFlightInfo:
    'Thông tin nối chuyến bay vui lòng liên hệ Booker để được giúp đỡ.',
  totalPayment: 'Tổng tiền thanh toán',
  alertNextDay: 'Chuyến bay đi vào ngày tiếp theo',
  alertNextAirport: 'Chuyển bay đi bị đổi sân bay',

  monthlyCheapestFare: 'Vé rẻ theo tháng',
  isGroupedItineraryResponse: 'Giá kết hợp',
  classBooking: 'Hạng vé',
  searchClass: {
    totalNet: 'Tổng giá',
    totalTax: 'Tổng thuế, phí',
    totalPrice: 'Tổng tiền',
  },

  fareRuleTimeStatus: {
    Anytime: 'Thời gian bất kỳ',
    'Before Departure': 'Trước khi khởi hành',
    BeforeDeparture: 'Trước khi khởi hành',
    refundable: 'Được phép hoàn',
    noRefundable: 'Không được phép hoàn',
    'Anytime And Before Departure': 'Thời gian bất kì và trước khi khởi hành',
    reschedulable: 'Được phép đổi ngày bay',
    noReschedulable: 'Không được phép đổi ngày bay',
    noshow: 'Được No Show',
    notNoshow: 'Không được No Show',
    'After Departure': 'Sau khi khởi hành',
    AfterDeparture: 'Sau khi khởi hành',
    'Anytime and Before Departure': 'Thời gian bất kỳ và trước khi khởi hành',
  },

  quickInputPax: 'Nhập tên nhanh',
  cheapestSearch: 'Tìm kiếm vé rẻ',
  selectDepartDay: 'Chọn ngày đi',
  selectReturnDay: 'Chọn ngày về',
  selectAirline: 'Chọn hãng(tuỳ chọn)',

  source: 'Hệ thống đặt vé',
  airlines: {
    QH: 'Bamboo Airways',
    VN1A: 'Vietnam Airlines',
    VN1A_MT: 'Vietnam Airlines Miền Trung',
    VN: 'Vietnam Airlines',
    VU: 'Vietravel Airlines',
    VJ: 'Vietjet Air',
    '1G': 'Travelport',
    NDC: 'NDC',
    GDS: 'GDS',
    '1S': 'VNA Miền Nam',
    '1S_CTRL': 'VNA Miền Trung',
    F1: 'Other',
    AK: 'AirAsia',
    TH: 'Scoot',
    '1A': 'Amadeus',
    '1B': 'Sabre',
  },
  sourceName: {
    QH: 'QH',
    VN1A: 'VN1A',
    VN1A_MT: 'VN1A_MT',
    VN: 'VN',
    VU: 'VU',
    VJ: 'VJ',
    '1G': '1G',
    NDC: 'NDC',
    GDS: 'GDS',
    '1S': '1S',
    '1S_CTRL': '1S_CTRL',
    F1: 'OF',
    AK: 'AK',
    TH: 'TR',
    '1A': '1A',
    '1B': '1B',
  },
  fareRule: 'Điều kiện vé',
  linkFareRule: 'Link điều kiện vé',

  checkboxReplaceAgencyData: 'Cập nhật thông tin khách hàng vào booking.',
  checkboxSendEmployeeMailAirline:
    'Cập nhật thông tin của nhân viên vào booking.',
  removeCustomerSelected: 'Bỏ chọn khách hàng',
  customer: 'Khách hàng',

  stopPoint: {
    Direct: 'Bay thẳng',
    '1 transit': '1 điểm dừng',
    '2 transits': '2 điểm dừng',
    '3 transits': '3 điểm dừng',
    '4 transits': '4 điểm dừng',
  },

  infoEmailReplace:
    'Nếu không nhập email, mặc định sẽ gửi lên hãng email của người đặt (đại lý).',

  splitItineraries: 'Tách chặng',
  boardingBaggage: 'Hành lý xách tay',
  checkinBaggage: 'Hành lý ký gửi',

  priceReport: {
    airline: 'Hãng bay',
    transitAt: 'Điểm dừng tại',
    flightNumber: 'Chuyến bay',
    departTime: 'Ngày bay',
    price: 'Tổng tiền',
    carryBag: 'Hành lý xách tay',
    checkinBag: 'Hành lý ký gửi',
  },
  'confirmChange&continue': 'Đồng ý thay đổi & tiếp tục',
  'confirmChange&book': 'Đồng ý thay đổi & giữ chỗ',
  errLengthPax: 'Lỗi độ dài thông tin khách hàng',
  callBooker1:
    'Vui lòng sửa lại thông tin hành khách hoặc liên hệ Booker để xử lý!',
  callBooker2:
    'Nếu bạn không đồng ý với sự thay đổi trên, vui lòng sửa lại thông tin hành khách hoặc liên hệ Booker để xử lý!',
  overLength1: 'Hành khách {lastName} {firstName} có thông tin quá dài.',
  overLength2:
    'Hành khách người lớn {adtLastName} {adtFirstName} và em bé {infLastName} {infFirstName} có thông tin quá dài.',
  overLength3:
    'Thông tin hành khách quá dài, trong trường hợp này em bé {infLastName} {infFirstName} sẽ được đổi tên thành {infLastName} {strNameInfant}',

  // ANCHOR agency balance
  agencyBalance: {
    title: 'Số dư hãng',
    fetchError: 'Lỗi tải dữ liệu số dư hãng',
    source: 'Hệ thống đặt vé',
    amount: 'Số dư hiện tại',
    warningBalance: 'Số dư cảnh báo',
  },
  full: 'Hết chỗ',
  flightInfo: 'Thông tin hành trình',
  fareNotIncludeTax: 'Giá vé chưa bao gồm thuế, phí',
  fareNotIncludeServiceFee: 'Chưa bao gồm phí dịch vụ',
  netFare: 'Giá vé',
  promotion: 'Chiết khấu',
  contactBookerForDiscount:
    'Chiết khấu hãng: Vui lòng xem bảng chiết khấu BSP - Hoặc liên hệ booker, sales',
  discountAirline: 'Khuyến mại hãng',
  taxes: 'Thuế, phí',
  agencyFee: 'Phí xuất vé',
  selectAirlineCreateMembershipCard: 'Chọn hãng bay tạo thẻ thành viên',
  creatingMembershipCard: 'Đăng kí thẻ thành viên',
  noItiSelected: 'Vui lòng chọn chuyến bay',
  flight: 'Chuyến bay',

  shortenBooking: 'Vé rút gọn',
  shortenBaggages: 'Hành lý',
  shortenFareRules: 'Điều kiện',

  setServiceFee: 'Sửa phí dịch vụ',
  useProfileServiceFee: 'Sử dụng profile',
  applyProfileServiceFeeSuccessful: 'Áp dụng phí dịch vụ thành công',

  classFilter: {
    ECONOMY: 'Phổ thông',
    PREMIUM_ECONOMY: 'Phổ thông đặc biệt',
    BUSINESS: 'Thương gia',
    FIRST: 'Hạng nhất',
  },

  fareRules: {
    title: 'Điều kiện vé',
    titleFromAirline: 'Điều kiện vé rút gọn',
    refundableAllow: 'Hoàn vé: Được phép',
    refundable: 'Được phép hoàn',
    refundableIdentify: 'Được phép hoàn định danh',
    noRefundable: 'Không được hoàn',
    contactBooker: 'Phí vui lòng liên hệ booker',
    reschedulableAllow: 'Đổi vé: Được phép',
    reschedulable: 'Được phép đổi',
    noReschedulable: 'Không được đổi',
    allow: 'Được phép',
  },
  combinationTab: {
    table: {
      paxType: 'Loại hành khách',
      amount: 'Số lượng',
      netFare: 'Giá vé',
      taxFare: 'Thuế và phí',
      totalFare: 'Tổng tiền',
    },
  },
  bagNotInclude: 'Không bao gồm',
  flightHaveStop: 'Chuyến bay có chặng ẩn',
  notSelectedFlights: 'Chưa chọn chuyến bay',
  neighboringDays: 'Ngày lân cận',
  showCheapestFareSwiper: 'Hiện ngày lân cận',
  hideCheapestFareSwiper: 'Ẩn ngày lân cận',
  terminalNumber: 'Ga số',
  groupBooking: {
    calculate: 'Tính giá đoàn',
    'Group pricing only supports domestic trips!':
      'Tính giá đoàn chỉ hỗ trợ các chuyến nội địa!',
    'Please select all itineraries!': 'Vui lòng chọn tất cả các hành trình!',
    'Group price calculation, group booking is not supported for VN1A trips!':
      'Tính giá đoàn, book đoàn không hỗ trợ với các chuyến VN1A!',
  },
  'You do not have permission to book airline tickets. Please contact the managing agent to book tickets!':
    'Bạn không có quyền đặt vé {airline}.\nVui lòng liên hệ đại lý chủ quản để book vé!',
  'VNA on the current itinerary does not support booking tickets with 2 different sources, please choose again!':
    'VNA trên hành trình hiện tại không hỗ trợ đặt vé 2 hệ thống khác nhau, vui lòng chọn lại!',
  'Departure time must always be after the end time of the previous journey!':
    'Thời gian khởi hành phải luôn sau thời gian kết thúc của hành trình trước!',
  'For VNA round-trip tickets, you must choose a VNA departure and return trip!':
    'Vé khứ hồi VNA bắt buộc phải chọn chuyến đi và chuyến về của VNA!',
  profit: 'Lợi nhuận',
  addBaggage: 'Thêm hành lý',
  addSeat: 'Thêm chỗ ngồi',
  addOtherServices: 'Dịch vụ khác',
  changePassengerAmount: 'Đổi số lượng',
  'Duplicate bookings': 'Các booking đã trùng lặp',
  Processing: 'Đang thực hiện...',
  'Reservation information': 'Thông tin đặt chỗ:',
  createBookingSuccessful: 'Đặt chỗ thành công!',
  bookingCode: 'Mã đặt chỗ',
  noInformation: 'Chưa có thông tin',
  errLoadingFlight: 'Lỗi tải thông tin chuyến bay!',
  plsCalcFlight: 'Vui lòng tính giá để xem giá hành trình',
  errCalcFlight: 'Lỗi tính giá hành trình',
  'Pricing information': 'Thông tin tính giá',
  'Total ticket price': 'Tổng tiền vé',
  'Please calculate the price to get the exact price':
    'Vui lòng tính giá để lấy giá chính xác!',
  showTransitFlight: 'Hiển thị nối chuyến',
  'Departure time': 'Thời gian khởi hành',
  combinatePrice: 'Kết hợp giá',
  'For Round-trip flights, please select Calculate combined price to get the exact price':
    'Chuyến bay "Khứ hồi" vui lòng chọn "Tính giá kết hợp" để lấy giá chính xác!',
  'To calculate the combined price, you need to choose 2 or more itineraries':
    'Tính giá kết hợp cần chọn từ 2 hành trình trở lên!',
  'Loading more flights': 'Đang tải thêm chuyến bay...',
  loadMore: 'Tải thêm',
}
